import React from 'react';
import { format } from 'date-fns';
import { Slot, T_EPG_Channel_New } from '../../../../type';
import useStore from '../../../../store';

interface SelectedEventProps {
  selectedEvent: Slot;
  selectedChannel: T_EPG_Channel_New;
}
export const SelectedEvent = ({ selectedEvent, selectedChannel }: SelectedEventProps) => {
  let rating = selectedEvent?.nzBsaRatingString;
  const titleValue = useStore(s => s.getTitleFromEvent);

  console.log('selectedEvent', selectedEvent);
  if (!selectedEvent) return <></>;
  return (
    <div className="flex items-center justify-center mx-auto md:w-3/4">
      <img className="m-2 w-24" alt="channel" src={selectedEvent?.channel?.tileImage?.uri} />
      <div className="m-2">
        <div className="text-xl">
          {titleValue(selectedEvent?.programme)}{' '}
          {selectedEvent?.nzBsaRatingString && <span className="text-sm">({rating})</span>}
        </div>
        <div className="text-base text-gray-darker">
          {format(selectedEvent?.startMs, 'EEEE')} {format(selectedEvent?.startMs, 'd')}{' '}
          {format(selectedEvent?.startMs, 'MMMM')} | {format(selectedEvent?.startMs, 'p')} -{' '}
          {format(selectedEvent?.endMs, 'p')} | Channel {selectedEvent?.channel?.number}
        </div>
      </div>
    </div>
  );
};
