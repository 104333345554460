import React from 'react';
import { useHistory } from 'react-router-dom';

// types
import { BoldedTitle } from './BoldedTitle';
import { Slot } from '../../type';

interface DropDownProps {
  list: Slot[] | undefined;
  close: Function;
  query: string;
}

export const DropDown = (props: DropDownProps) => {
  const { list, close, query } = props;
  const history = useHistory();

  const redirect = (title: string) => {
    close();
    history.push(`/search?query=${encodeURIComponent(title)}`);
  };

  return (
    <ul
      style={{ maxHeight: '14rem' }}
      className="overflow-y-auto overflow-x-hidden absolute bg-mint z-10 inset-x-0 mx-px">
      {list?.map(
        (event,index) =>
          event?.title != null && (
            <li
              data-testid="search-epg-autosuggest-result"
              key={index}
              className="whitespace-no-wrap text-white bg-navy hover:bg-opacity-85">
              <div
                className="flex py-2 text-lg mx-4 border-b z-10 cursor-pointer"
                onClick={() => redirect(event?.title)}>
                {<BoldedTitle title={event?.title} query={query} />}
              </div>
            </li>
          )
      )}
    </ul>
  );
};
