import { ApolloClient, InMemoryCache, createHttpLink,HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getClient } from '../store/authStore/authStore';
import { EPG_DIGITAL_API } from '../config';

// const PRELIVE_URL = 'https://api-sit.skyone.co.nz/exp/graph';
// const PROD_URL = 'https://api.skyone.co.nz/exp/graph';
// const DEV_URL = 'https://api-dev.skyone.co.nz/exp/graph';

// const httpLink = createHttpLink({
//   uri: EPG_DIGITAL_API,
// });
const httpLink = new HttpLink({
  uri: EPG_DIGITAL_API,
  useGETForQueries: true,
});

const authLink = setContext(async (_, { headers }) => {
   const isAuthenticated = await getClient()?.isAuthenticated();
  if (isAuthenticated){
  try {
    const accessToken = await getClient()?.getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'x-sky-app-id': 'TvGuide-Web',
      },
    };
  } catch (e) {
    // @ts-ignore
    // console.log('err==', e);
    if (e.error === 'login_required') {
      return {
        headers: {
          ...headers,
          'x-sky-app-id': 'TvGuide-Web',
        },
      };
    }

    return null;
  }}
});

export const gqlAgentMyAccount = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
