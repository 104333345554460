import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import RecordModalManager from './modal/RecordModalManager';
import { getProducts } from '../../services/index';

import { useToastContainer, T_Product, useSplitIO, SPLITIO_KEY, productSkuIds } from '@sky-tv-group/shared';
import { Slot, T_EPG_Channel_New } from '../../type';
import { Loader, Modal } from '@sky-tv-group/components';
import { RecordButton } from './RecordBtn';
import { useAuth0 } from '../../store/authStore/authStore';
import { gqlAgentMyAccount } from '../../services/agent';
import { getRecordProgram } from '../../graphQL/mutations/getRecordProgrm';
import { getCustomerDetails } from '../../graphQL/queries/getCustomerDetails';
import { getRecordSeriesLink } from '../../graphQL/mutations/getRecordSeriesLink';

interface RecordButtonsProps {
  channel: T_EPG_Channel_New;
  event: Slot;
  className?: string;
}
export let RecordButtons = ({ channel, event: selectedEvent, className = 'justify-center' }: RecordButtonsProps) => {
  let overrideProductInfoFromKK = useSplitIO(SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART);

  let [modalIsOpen, setIsOpen] = useState(false);
  let [seriesRecord, setSeriesRecord] = useState(false);
  let [missingSubscription, setMissingSubscription] = useState<T_Product | undefined>(undefined);
  let [loading, setLoading] = useState(false);
  const { addToast, addSuccessToast } = useToastContainer();
  const updateSelectedEvent = useStore(s => s.updateSelectedEvent);
  const packages = useStore(s => s.packages);
  const titleValue = useStore(s => s.getTitleFromEvent);
  let title = titleValue(selectedEvent?.programme);
  const [nsbRemoteRecording] = useSplitIO(SPLITIO_KEY.NSB_REMOTE_RECORDING);

  const occurrences = useStore(s => s.occurrences)?.filter(o => {
    // If it's a NSB don't show remote recording as an option yet
    // This is going to be toggled on using the NSB_REMOTE_RECORDING feature flag
    if (
      // If a NSB
      o.entitlements.find(
        e => e.code === productSkuIds?.arrowBox?.primary || e.code === productSkuIds?.arrowBoxBlack?.primary
      ) &&
      // And the NSB remote recording flag is off
      !nsbRemoteRecording
    )
      return false;

    return o.isRemoteRecordCapable;
  });
  const hasRecordableBox = (occurrences?.length ?? 0) > 0;

  function openModal(isSeries: boolean = false) {
    setSeriesRecord(isSeries);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  // Get Profile ID from graphQL
  // let profileID = useStore(s => s.profileID)
  // console.log(profileID,'chhhecccckkk')

  // If there's an SeriesRecord flag from session, open modal and clean flag
  useEffect(() => {
    const isSeriesRecordFromSession = sessionStorage.getItem('isSeriesRecord');
    if (selectedEvent && isSeriesRecordFromSession) {
      if (channel?.number == selectedEvent?.channel?.number) {
        openModal(isSeriesRecordFromSession === 'true');
        sessionStorage.removeItem('isSeriesRecord');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  // If the user is subscribed or not to the package
  // useEffect(() => {
  //   let findMissingSubscription = async () => {
  //     let products = await getProducts(overrideProductInfoFromKK ? true : false);
  //     setMissingSubscription(products?.find((prod: { sku: string }) => prod?.sku === channel?.sku));
  //   };

  //   // wait for packages to get fetched (billing api takes ~2 seconds to load)
  //   if (!packages && isAuthenticated) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //     let packageFound = packages?.find(pkg => pkg.sku === channel?.sku);
  //     if (!packageFound) {
  //       findMissingSubscription();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [packages]);

  //to call once we click on recording
  const remoteRecording = async (cardID: string) => {
    // console.log('cardID in Record Click', cardID);
    const { data: profileData } = await gqlAgentMyAccount.query({
      query: getCustomerDetails,
    });
    let profileID = profileData?.customer?.profile?.id;

    let variables = seriesRecord
      ? {
          deviceId: cardID,
          profileId: profileID,
          seriesLinkId: selectedEvent?.recordingSeriesLink?.id,
          appId: 'TV_GUIDE_WEB',
        }
      : {
          deviceId: cardID,
          profileId: profileID,
          automaticConflictResolution: true,
          slotId: selectedEvent?.id,
          appId: 'TV_GUIDE_WEB',
        };
    try {
      // console.log("variables",variables)
      const res = await gqlAgentMyAccount.mutate({
        mutation: seriesRecord ? getRecordSeriesLink : getRecordProgram,
        variables: variables,
      });
      if (
        (res?.data?.recordSeriesLink?.version == 0 && res?.data?.recordSeriesLink?.recordRequests?.length == 0) ||
        (res?.data?.recordProgram?.version == 0 && res?.data?.recordProgram?.recordRequests?.length == 0)
      ) {
        addSuccessToast({
          message: `${title} on
        ${selectedEvent?.channel?.title} (Channel ${selectedEvent?.channel?.number}) at
        ${format(selectedEvent?.startMs, 'p')}on ${format(selectedEvent?.startMs, 'EEEE')}
        ${format(selectedEvent?.startMs, 'd')}${' '} ${format(
            selectedEvent?.startMs,
            'MMMM'
          )}has been scheduled to record on your SKY box`,
        });
      } else if (
        (res?.data?.recordSeriesLink?.subscriptions?.length > 0 &&
          res?.data?.recordSeriesLink?.__typename === 'SubscriptionNeeded') ||
        (res?.data?.recordProgram?.subscriptions?.length > 0 &&
          res?.data?.recordProgram?.__typename === 'SubscriptionNeeded')
      ) {
        addToast({
          title: 'Alert',
          type: 'error',
          message: 'You do not have enough subscriptions to record this program. Please upgrade your subscription',
        });
      } else if (
        res?.data?.recordSeriesLink?.__typename === 'DeviceNotRecordCapable' ||
        res?.data?.recordProgram?.__typename === 'DeviceNotRecordCapable'
      ) {
        addToast({
          title: 'Alert',
          type: 'error',
          message: 'Your device does not have recording capability.',
        });
      } else {
        addToast({
          title: 'Alert',
          type: 'error',
          message: 'Something went wrong... Please try again or contact support.',
        });
      }
      setLoading(false);
      closeModal();
      updateSelectedEvent(undefined);
    } catch (error) {
      addToast({
        title: 'Alert',
        type: 'error',
        message: 'Something went wrong... Please try again or contact support.',
      });
    }
  };

  // const remoteRecording = (cardID: string) => {
  //   if (selectedEvent) {
  //     setLoading(true);
  //     remoteRecord({
  //       eventID: selectedEvent.programme.id,
  //       smartCardID: cardID,
  //       ...(seriesRecord && selectedEvent.programme.show.id && { seriesId: Number(selectedEvent.programme.show.id) }),
  //     })
  //       .then(() => {
  //         addSuccessToast({
  //           message: `${selectedEvent.programme.title} on ${selectedEvent.channel.title} (Channel ${selectedEvent.channel.number}) at ${format(
  //             selectedEvent?.startMs,
  //             'p'
  //           )}
  //                     on ${format(selectedEvent.startMs, 'EEEE')} ${format(selectedEvent.startMs, 'd')}${' '} ${format(
  //             selectedEvent.startMs,
  //             'MMMM'
  //           )}
  //             has been scheduled to record on your SKY box`,
  //         });
  //       })
  //       .catch((err: Error) => {
  //         addToast({
  //           title: 'Alert',
  //           type: 'error',
  //           message: 'Something went wrong... please try again.',
  //         });
  //       })
  //       .finally(() => {
  // setLoading(false);
  // closeModal();
  // updateSelectedEvent(undefined);
  //       });
  //   }
  // };

  const modalProps = {
    isSeriesRecord: seriesRecord,
    loading,
    remoteRecording,
    closeModal,
    event: selectedEvent,
    channel,
    missingSubscription,
  };
  return (
    <div className={`flex items-center mt-5 ${className}`}>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={() => closeModal()}>
          {loading ? <Loader /> : <RecordModalManager {...modalProps} />}
        </Modal>
      )}
      {selectedEvent?.recordOptions?.includes('EPISODE') && selectedEvent?.id && (
        <RecordButton variant="Episode" onClick={() => openModal(false)} />
      )}
      {selectedEvent?.recordOptions?.includes('SERIES') && selectedEvent?.recordingSeriesLink?.id && (
        <RecordButton variant="Series" onClick={() => openModal(true)} />
      )}
    </div>
  );
};
