import React from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Loader } from '@sky-tv-group/components';
import useStore from '../store';
import { useEffect } from 'react';
import { TVGuideHeader } from './TVGuideHeader';
import { TVGuideControl } from './TVGuideControl';
import EventDetailNew from './event/EventDetailNew';
import { Slot, T_EPG_Channel_New } from '../type';
interface Props {
  selected: boolean;
  channel: T_EPG_Channel_New;
  event: Slot;
  onClick: (e: Slot) => void;
}

const EventBlock = ({ channel, event, selected, onClick }: Props) => {
  const live = event.live; 

  const titleValue = useStore(s => s.getTitleFromEvent);
  let title = titleValue(event?.programme);
  return (
    <div className="mb-1 mt-1">
      <div className="w-screen-90 mx-auto cursor-pointer">
        <div className="lg:w-13/14 mx-auto flex flex-wrap border border-gray-light">
          <div className={`w-2 ${selected ? 'sky-morpheus-dan-gradient' : ''}`}></div>
          <div
            className="flex flex-1 flex-wrap  py-5 px-5 md:px-8"
            onClick={() => {
              onClick(event);
            }}>
            <p className="sky-h5 font-bold md:font-normal w-3/10 md:w-2/14">{format(event.startMs, 'p')}</p>
            <p className="sky-h5 font-bold">{title}</p>
            {live && <p className="text-sm ml-4 md:ml-10 text-white bg-green rounded-round px-3 pt-1">On Air</p>}
          </div>
        </div>
      </div>
      {selected && <EventDetailNew channel={channel} event={event} />}
    </div>
  );
};

const ChannelEventsNew = () => {
  const { channelNumber } = useParams<{ channelNumber: string }>();

  const { dayOfX, loading, newChannels, selectedEvent } = useStore(s => ({
    dayOfX: s.dayOfX, // current selected day in the weekslots
    loading: s.loading,
    newChannels: s.newChannels,
    selectedEvent: s.selectedEvent,
  }));
  const updateSelectedEvent = useStore(s => s.updateSelectedEvent);
  const updateChannelFilter = useStore(s => s.updateChannelFilter);

  // get events on the current selected day

  const channel = newChannels.find(ch => ch.number == channelNumber);

  const current = new Date().getTime();

  const events = channel?.slotsForDay?.slots.filter(ch => {
    return current <= ch.endMs;
  });

  const hasEvents = !loading && events && events.length > 0;

  // update channel for search
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    updateChannelFilter(channel);
    return () => {
      updateChannelFilter(); // reset channel filter
    };
  }, [channel ? channel.number : null]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEventClick = (event: Slot) => {
    updateSelectedEvent(
      selectedEvent && selectedEvent?.programme?.id === event?.programme?.id ? undefined : event,
      channel
    );
  };

  return (
    <>
      <div className="sticky top-4 z-20 bg-white">
        <TVGuideHeader />
        <TVGuideControl />
      </div>
      <div className="overflow-hidden relative" style={{ zIndex: 9 }}>
        <div className="w-screen-90 flex my-0 mx-auto relative z-10">
          <div className={`w-full mb-20 ${loading ? 'min-h-screen' : ''}`}>
            {loading && (
              <div className="absolute w-full h-screen">
                <Loader bgOpacity={false} />
              </div>
            )}
            {hasEvents &&
              channel &&
              events?.map(event => {
                let selected =
                  selectedEvent?.programme?.id != undefined
                    ? selectedEvent?.programme?.id == event?.programme?.id &&
                      selectedEvent?.channel?.id == event?.channel?.id
                    : false;
                return (
                  <EventBlock
                    key={event.id}
                    channel={channel}
                    event={event}
                    selected={selected}
                    onClick={onEventClick}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelEventsNew;
