import { gql } from '@apollo/client';

export const getRecordProgram = gql`
mutation RecordProgram($deviceId: ID!
    $profileId: ID!
    $slotId: ID!
    $automaticConflictResolution: Boolean!
    $appId: AppId! ) {
    recordProgram(
        deviceId: $deviceId
        profileId: $profileId
        slotId: $slotId
        automaticConflictResolution: $automaticConflictResolution
        appId: $appId
    ) {
        ... on SubscriptionNeeded {
            subscriptions {
                id
                title
                __typename
            }
            __typename
        }
        ... on DeviceNotRecordCapable {
            device {
                id
            }
            __typename
        }
        ... on QueuedRecordingSet {
            version
            recordRequests {
                __typename
            }
            __typename
        }
        __typename
    }
}


`
