const EPG_SEARCH_API = process.env.REACT_APP_EPG_ENDPOINT!;
const EPG_CHANNELS_API = process.env.REACT_APP_EPG_CHANNELS_ENDPOINT!;
const ACCOUNT_API = process.env.REACT_APP_ENDPOINT!;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_TVGUIDE!;
const ICE_URL = process.env.REACT_APP_ICE_URL!;
const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
const LIFERAY_CONNECT_ENDPOINT = process.env.REACT_APP_LIFERAY_CONNECT_ENDPOINT!;
const DOMAIN = process.env.REACT_APP_DOMAIN!;
const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
const SPLIT_KEY = 'skyweb';
const KONAKART_ENDPOINT = process.env.REACT_APP_KONAKART_ENDPOINT!;
const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
const APP_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!;
const EPG_DIGITAL_API = process.env.REACT_APP_EPG_DIGITAL_API!;
// Sentry deny urls as arrays of RegExps
const SENTRY_DENY_URLS = (process.env.REACT_APP_SENTRY_DENY_URLS || '')
  .split(',')
  .filter(s => !!s)
  .map(s => new RegExp(s, 'i'));

const genreFilters = [
  'All Channels',
  'Sports',
  'Entertainment & Lifestyle',
  'Movies',
  'Kids',
  'News & Documentaries',
] as const;

export {
  EPG_SEARCH_API,
  EPG_CHANNELS_API,
  ACCOUNT_API,
  SENTRY_DSN,
  NAVIGATION_URL,
  AUTH0_LOGOUT_URL,
  LIFERAY_CONNECT_ENDPOINT,
  DOMAIN,
  SPLIT_AUTHORIZATION_KEY,
  SPLIT_KEY,
  KONAKART_ENDPOINT,
  ACCOUNT_URL,
  genreFilters,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
  EPG_DIGITAL_API,
  ICE_URL,
};
