import { NotFoundContainer } from '@sky-tv-group/components';
import ChannelEvents from './components/ChannelEvents';
import { Search } from './components/SearchResult';
import Slider from './components/Slider';
import ChannelEventsNew from './components/ChannelEventsNew';

export let routes = [
  {
    path: '/',
    title: 'Home',
    component: Slider,
    exact: true,
  },
  {
    path: '/search',
    title: 'Search',
    component: Search,
    exact: true,
  },
  {
    path: '/channel/:channelNumber/search',
    title: 'Channel Search',
    component: Search,
    exact: true,
  },
  {
    path: '/channel/:channelNumber',
    title: 'Events',
    component: ChannelEventsNew,
    exact: true,
  },
  {
    path: '/notfound',
    title: 'Not Found',
    component: NotFoundContainer,
    exact: true,
  },
];
