import { createBrowserHistory } from 'history';
import { useAnalytics } from '@sky-tv-group/shared';

const { page } = useAnalytics();

const history = createBrowserHistory();

history.listen(location => {
  if ('noScroll' in (location?.state ?? {})) return;

  setTimeout(() => {
    if (location?.hash) {
      const id = location.hash.slice(1); // Remove the '#' from the hash
      const validId = /^[A-Za-z][\w:.-]*$/.test(id); // Check if it's a valid CSS ID

      if (validId) {
        let ele = window.document.querySelector(location.hash);
        if (ele) {
          ele.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        console.error(`Invalid CSS selector: ${location.hash}`);
      }
    }
  }, 1);

  page();
});

export default history;