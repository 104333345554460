import create from 'zustand';
import { format } from 'date-fns';

import { navService, getBoxes, getProfile, getPackagesSubscription } from '../services/index';
import { FETCH_RANGE } from '../constants';
import { NAVIGATION_URL } from '../config';
import { T_EPG_Channel, T_EPG_Event, NavigationLinks, T_Product } from '@sky-tv-group/shared';
import { pixel2Time } from '../utils';

import { T_Occurrence, T_Profile } from '@sky-tv-group/shared';
import { genreFilters } from '../config';
import { gqlAgentMyAccount } from '../services/agent';
import { getChannelGroup } from '../graphQL/queries/getChannelGroup';
import { getChannelGroups } from '../graphQL/queries/getChannelGroups';
import { Programme, Slot, T_EPG_Channel_New } from '../type';

export interface Store {
  profile?: T_Profile;
  occurrences?: T_Occurrence[];
  packages?: T_Product[];
  channels: T_EPG_Channel[];
  newChannels: T_EPG_Channel_New[];
  channelFilter?: number;
  genre?: typeof genreFilters[number];
  genreId: string;
  init: number;
  x: number;
  dayOfX: string;
  delta: number;
  latest: number;
  selectedEvent?: Slot;
  selectedChannel?: T_EPG_Channel_New;
  hasError: boolean;
  loading: boolean;
  dragging: boolean;
  navLinks: NavigationLinks;
  fetch: () => void;
  fetchNavLinks: () => void;
  updateSelectedEvent: (event?: Slot, channel?: T_EPG_Channel_New) => void;
  updateChannelFilter: (channel?: T_EPG_Channel_New) => void;
  updateDrag: (s: any) => void;
  setX: (newX: number) => void;
  updateGenre: (genre: typeof genreFilters[number] | '') => void;
  updateGenreId: (genreId: string) => void;
  hasErrorSet: (hasError: boolean) => void;
  setOccurrences: () => void;
  setProfile: () => void;
  setPackages: () => void;
  from: string;
  to: string;
  setFrom: (from: string) => void;
  setTo: (from: string) => void;
  profileID: string;
  getTitleFromEvent: (programme?: Programme) => string;
}

const [useStore, store_] = create<Store>((set, get) => ({
  from: '',
  to: '2024-06-25T23:01:28Z',
  channels: [],
  newChannels: [],
  profileID: '',
  singleChannelEvents: [],
  navLinks: {
    logoUrl: '/',
    logoSrc: '/',
    TVGuideURL: '/',
    header: [],
    side: [],
    footer: [],
    social: [],
    corporate: [],
  },
  getTitleFromEvent: (programme?: Programme) => {
    let title = 'Programme Information Unavailable';
    if (programme?.__typename == 'Movie') {
      title = programme?.title;
    } else if (programme?.__typename == 'Episode') {
      title = programme?.show?.type == 'SPORT' ? programme?.title : programme?.show?.title ?? programme?.title;
    } else if (programme?.__typename == 'PayPerViewEventProgram') {
      title = programme?.title;
    }
    return title;
  },
  updateSelectedEvent: (selectedEvent?: Slot, selectedChannel?: T_EPG_Channel_New) => {
    set({ selectedEvent, selectedChannel });
  },
  channelFilter: undefined,
  updateChannelFilter: (channelFilter?: T_EPG_Channel_New) => {
    set({ channelFilter: channelFilter ? Number(channelFilter.number) : undefined });
  },
  genre: undefined,
  updateGenre: genre => {
    set({ genre: genre ? genre : undefined, selectedEvent: undefined });
  },
  genreId: '',
  updateGenreId: genreId => {
    set({ genreId: genreId ? genreId : undefined, selectedEvent: undefined });
  },
  // TODO
  updateDrag: ({ delta, first, last, movement, velocity }: any) => {
    if (first) {
      set({ dragging: true });
    }
    if (last) {
      set({ dragging: false });
    }
    if (!first && !last && velocity > 0.5) {
      set({ selectedEvent: undefined });
    }
    set(s => {
      const newX = Math.max(0, s.x - delta[0]);
      return {
        delta: movement[0],
        x: newX,
        dayOfX: format(pixel2Time(newX) + s.init, 'P'),
      };
    });
  },
  setX: (newX: number) => {
    set(s => {
      return {
        x: newX,
        dayOfX: format(pixel2Time(newX) + s.init, 'P'),
        selectedEvent: undefined,
      };
    });
  },
  setFrom: (from: string) => {
    set(s => {
      return {
        from: from,
      };
    });
  },
  setTo: (to: string) => {
    set(s => {
      return {
        to: to,
      };
    });
  },
  fetch: async () => {
    const { x, latest, init, genreId, from, setFrom, updateGenreId } = get();
    const cursor = pixel2Time(x) + init;
    set({ loading: true });
    const update = async () => {
      //initial calculation of genreID
      let id = genreId;
      if (id === '') {
        const res = await gqlAgentMyAccount.query({
          query: getChannelGroups,
        });
        id = res?.data?.experience?.channelGroups[0].id;
        updateGenreId(res?.data?.experience?.channelGroups[0].id);
      }

      //initial calculation of fromDate
      let fromDate;
      if (from == '') {
        const currentYear = new Date().getFullYear();
        const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
        let date = new Date()
          .getDate()
          .toString()
          .padStart(2, '0');
        fromDate = `${currentYear}-${currentMonth}-${date}`;
        setFrom(fromDate);
      }

      // const profileData = await gqlAgentMyAccount.query({
      //   query: getCustomerDetails
      // })
      // const profileD = profileData?.customer?.profile?.id
      // set({profileID:profileD})

      //Getting channel groups
      const res = await gqlAgentMyAccount.query({
        query: getChannelGroup,
        variables: {
          id: genreId !== '' ? genreId : id,
          date: from !== '' ? from.toString() : fromDate,
          // to: to,
        },
      });
      let newChannelsResponse: any[] = res?.data?.experience?.channelGroup?.channels; // Assuming res is your response object

      let modifiedChannelsResponse: any[] = [];

      if (newChannelsResponse && newChannelsResponse.length > 0) {
        modifiedChannelsResponse = newChannelsResponse.map((channel: any) => {
          let modifiedChannel = { ...channel }; // Create a shallow copy of the channel object

          // Check if slotsForDay and slots exist and have elements
          if (modifiedChannel.slotsForDay?.slots && modifiedChannel.slotsForDay.slots.length > 0) {
            // Create a new object for slotsForDay with a new array for slots
            modifiedChannel = {
              ...modifiedChannel,
              slotsForDay: {
                ...modifiedChannel.slotsForDay,
                slots: modifiedChannel.slotsForDay.slots.map((slot: any) => ({
                  ...slot,
                  channel: {
                    id: channel.id,
                    title: channel.title,
                    number: channel.number,
                    tileImage: channel.tileImage,
                  },
                  startFromInit: slot.startMs - init,
                  endFromInit: slot.endMs - init,
                })),
              },
            };
          }

          return modifiedChannel;
        });
      }
      // console.log('modifiedChannelsResponse:', modifiedChannelsResponse);
      set({
        newChannels: modifiedChannelsResponse,

        loading: false,
      });
    };
    update();
    if (cursor < latest) return;
    set({ latest: cursor + FETCH_RANGE });
  },
  fetchNavLinks: async () => {
    const navLnk = await navService.getLinks(NAVIGATION_URL);
    set({ navLinks: navLnk });
  },
  init: Date.now(),
  dayOfX: format(Date.now(), 'P'),
  x: 0,
  delta: 0,
  latest: 0,
  hasError: false,
  loading: false,
  dragging: false,
  hasErrorSet: (hasError: boolean) => {
    set({ hasError });
  },
  setOccurrences: async () => {
    let occurrences = await getBoxes();
    // console.log('occurrences', occurrences);
    set({ occurrences });
  },
  setProfile: async () => {
    let profile = await getProfile();
    set({ profile });
  },
  setPackages: async () => {
    let packages = await getPackagesSubscription();
    set({ packages });
  },
}));

export default useStore;
export const store = store_;
export const useChannels = () => {
  const channels = useStore(s => s.channels);
  const genre = useStore(s => s.genre);
  return channels.filter(channel => (genre === undefined ? true : channel.genre.includes(genre)));
};
