import { format } from 'date-fns';
import React, { useEffect, useReducer, useRef, useCallback } from 'react';

import { MINUTES_OF_MONTH, PIXEL_PER_MINUTE } from '../constants';
import useStore, { store } from '../store';
import { time2Pixel } from '../utils';
import { Link } from 'react-router-dom';
import EventDetailNew from './event/EventDetailNew';
import { Slot, T_EPG_Channel_New } from '../type';

const BUFFER_WIDTH = 3000;
const ABSOLUTE_POS_OFFSET = 0;

interface EventBlockProps {
  event: Slot;
  selected: boolean;
  handleClick: (e: Slot) => void;
  start: any;
  end: any;
  init: any;
}

const EventBlock = ({ event, selected, handleClick, init }: EventBlockProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentTime = new Date().getTime();
  const live = currentTime >= event.startMs && currentTime <= event.endMs;
  const titleValue = useStore(s => s.getTitleFromEvent);
  let title = titleValue(event?.programme);

  let setLeft = useCallback((x: number) => {
    if (ref.current) {
      if (x !== null && x > time2Pixel(event.startFromInit) && x < time2Pixel(event.endFromInit)) {
        ref.current.style.left = x - time2Pixel(event.startFromInit) + 'px';
      } else {
        ref.current.style.left = '0px';
      }
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const unsub = store.subscribe(
      (x: number | null) => {
        x !== null && setLeft(x);
      },
      s => s.x
    );
    setLeft(store.getState().x);

    return unsub;
    // eslint-disable-next-line
  }, []);

  return (
    <div
      data-testid="channel-event"
      key={event.id}
      className={`absolute t-0 h-full border-gray-light border cursor-pointer select-none ${live &&
        'bg-gray-medium'} ${selected && 'bg-gray-light'}`}
      style={{
        width: time2Pixel(event.endMs - event.startMs),
        transform: `translateX(${time2Pixel(event.startFromInit)}px)`,
      }}
      onClick={() => {
        event.programme !== null && handleClick(event);
      }}>
      <div
        ref={ref}
        className="absolute overflow-hidden p-3 inset-0"
        style={{
          left: '0',
        }}>
        <div data-testid="channel-event-title" className="text-base truncate font-bold">
          {title}
        </div>
        <div data-testid="channel-event-time" className="text-sm truncate">
          {format(event.startMs, 'p')} - {format(event.endMs, 'p')}
        </div>
      </div>
      {selected && (
        <div
          className="absolute left-1/2 w-0 h-0"
          style={{
            bottom: '-1px',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid #00B1EB',
          }}></div>
      )}
    </div>
  );
};

interface Props {
  channel: T_EPG_Channel_New;
}
const ChannelRailNew = ({ channel }: Props) => {
  const selectedEvent = useStore(s => s.selectedEvent);
  const loading = useStore(s => s.loading);
  const updateSelectedEvent = useStore(s => s.updateSelectedEvent);
  const updateChannelFilter = useStore(s => s.updateChannelFilter);
  const forceUpdate = useReducer(s => s + 1, 0)[1];
  const events = channel.slotsForDay?.slots;
  const xRef = useRef(store.getState().x);
  const draggingRef = useRef(false);
  const deltaRef = useRef(0);
  const ref = useRef<HTMLDivElement>(null);
  const init: any = useRef(store.getState().init);

  useEffect(() => {
    updateSelectedEvent(undefined);
  }, []);
  useEffect(() => {
    const unsub = store.subscribe(
      (s: { dragging: boolean; x: number; delta: number } | null) => {
        if (!s) return;
        const { dragging, x, delta } = s;
        draggingRef.current = dragging;
        deltaRef.current = delta;
        if (!dragging && Math.abs(x - xRef.current) > BUFFER_WIDTH / 2) {
          xRef.current = x;
          forceUpdate();
        }
      },
      ({ dragging, x, delta }) => ({ dragging, x, delta })
    );
    return unsub;
  }, [forceUpdate]);

  useEffect(() => {
    const unsub = store.subscribe(
      (x: number | null) => {
        if (ref.current) {
          ref.current.style.left = -(x || 0) + 'px';
        }
      },
      s => s.x
    );
    return unsub;
  }, []);

  const handleClick = (event: Slot) => {
    deltaRef.current === 0 &&
      updateSelectedEvent(
        selectedEvent && selectedEvent?.programme?.id === event?.programme?.id ? undefined : event,
        channel
      );
  };

  const events2Display = events.filter(event => {
    return (
      time2Pixel(event.endFromInit) > xRef.current - BUFFER_WIDTH &&
      time2Pixel(event.startFromInit) < xRef.current + BUFFER_WIDTH
    );
  });
  return (
    <div>
      <div
        id={channel.number}
        key={channel.number}
        className="w-screen-90 relative my-0 mx-auto overflow-hidden pl-24 md:pl-32 h-16 md:h-20">
        <div className="absolute bg-white left-0" style={{ zIndex: 101 }}>
          <Link
            key={channel.number}
            to={`/channel/${channel.number}`}
            className="block border-gray-light border-b-2 border-r-4 pt-1 pl-1 w-24 md:w-32 h-16 md:h-20"
            onClick={() => {
              updateSelectedEvent(undefined);
              updateChannelFilter(channel);
            }}>
            <div className="text-sm absolute top-0 left-0 m-1">{channel.number.toString().padStart(3, '0')}</div>
            <div
              className="h-10 md:h-12 bg-center bg-contain bg-no-repeat m-2"
              style={{
                backgroundImage: `url("${channel.tileImage.uri}")`,
              }}
            />
          </Link>
        </div>

        <div className="w-full relative">
          <div
            ref={ref}
            className="h-16 md:h-20"
            style={{
              width: MINUTES_OF_MONTH * PIXEL_PER_MINUTE,
              position: 'absolute',
              left: -store.getState().x + ABSOLUTE_POS_OFFSET,
            }}>
            {!loading && events2Display?.length < 1 && (
              <div className="absolute overflow-hidden p-3 inset-0 border-gray-light border text-base font-bold">
                <span
                  style={{
                    transform: `translateX(${
                      store.getState().x === 0 ? store.getState().x : store.getState().x + ABSOLUTE_POS_OFFSET
                    }px)`,
                    position: 'absolute',
                  }}>
                  Programme Information Unavailable
                </span>
              </div>
            )}
            {events2Display?.map(event => {
              let selected =
                selectedEvent?.programme?.id != undefined
                  ? selectedEvent?.programme?.id == event?.programme?.id &&
                    selectedEvent?.channel?.id == event?.channel?.id
                  : false;
              return (
                <EventBlock
                  key={event.id}
                  event={event}
                  selected={selected}
                  handleClick={handleClick}
                  start={event.startMs}
                  end={event.endMs}
                  init={init}
                />
              );
            })}
          </div>
        </div>
      </div>
      <EventDetailNew channel={channel} />
    </div>
  );
};

export default ChannelRailNew;
