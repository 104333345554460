import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import useStore from '../../store';
import { productSkuIds, SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { RecordButtons } from './RecordButtons';
import { myAccountSWR } from '../../services';
import { Slot, T_EPG_Channel_New } from '../../type';
import { gqlAgentMyAccount } from '../../services/agent';
import { getSlot } from '../../graphQL/queries/getSlot';
// import { BlockLoader } from '@sky-tv-group/components';
interface EventProps {
  channel: T_EPG_Channel_New;
  event?: Slot;
}

const EventDetailNew = ({ channel, event }: EventProps) => {
  const [nsbRemoteRecording] = useSplitIO(SPLITIO_KEY.NSB_REMOTE_RECORDING);
  const selectedEvent = useStore(s => s.selectedEvent);
  const [eventDetail, setEventDetail] = useState<Slot>();
  const [isRecordable, setIsRecordable] = useState();
  const [rating, setRating] = useState('');
  const [loading, setLoading] = useState(false);

  const occurrences = useStore(s => s.occurrences)?.filter(o => {
    // If it's a NSB don't show remote recording as an option yet
    // This is going to be toggled on using the NSB_REMOTE_RECORDING feature flag
    if (
      // If a NSB
      o.entitlements.find(
        e => e.code === productSkuIds?.arrowBox?.primary || e.code === productSkuIds?.arrowBoxBlack?.primary
      ) &&
      // And the NSB remote recording flag is off
      !nsbRemoteRecording
    )
      return false;

    return o.isRemoteRecordCapable;
  });
  const hasRecordableBox = (occurrences?.length ?? 0) > 0;

  const showEventDetail =
    selectedEvent !== undefined &&
    channel?.number == selectedEvent?.channel?.number &&
    // check if event is passed and the same as selectedEvent, else disregard and return true
    (event ? event.id === selectedEvent.id : true);

  // preload data for recording modal
  myAccountSWR.usePackages();

  const titleValue = useStore(s => s.getTitleFromEvent);
  let title = titleValue(selectedEvent?.programme);

  const getSlotDetail = async () => {
    setLoading(true);
    const res = await gqlAgentMyAccount.query({
      query: getSlot,
      variables: { slotId: selectedEvent?.id },
    });
    console.log("slotDetail with other Info res",res)

    let programme = {
      ...selectedEvent?.programme,
      ...res?.data?.experience?.linearChannelSlot?.programme
    }
    let slotDetail = {
      ...selectedEvent,
      ...res?.data?.experience?.linearChannelSlot,
      programme:programme
    };
    console.log("slotDetail with other Info",slotDetail)
    setEventDetail(slotDetail);

    const isRecordable =
      slotDetail?.recordOptions?.includes('EPISODE') || slotDetail?.recordOptions?.includes('SERIES');
    setIsRecordable(isRecordable);
    // let rating = slotDetail?.rating?.classification.replace(/_/g, '');
    let rating = slotDetail?.nzBsaRatingString;
    setRating(rating);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedEvent && showEventDetail) {
      getSlotDetail();
    }
  }, [selectedEvent]);

  // if (loading) {
  //   return <div />;
  // }

  return selectedEvent !== undefined && showEventDetail ? (
    <div className="relative h-96 md:h-72 lg:h-56">
      <div
        className="absolute bg-blue-light h-96 md:h-72 lg:h-56"
        style={{
          width: '100vw',
          zIndex: 102,
          left: '50%',
          transform: 'translateX(-50%)',
        }}>
        <div className="flex flex-wrap md:flex-no-wrap text-white py-8 px-5 md:py-6 md:px-4 my-0 mx-auto h-full">
          <div className="w-full flex flex-col justify-center md:w-2/4 mx-5 lg:ml-32 flex-grow">
            <div className="sky-h4 lg:sky-h5 font-semibold">
              {title}
              {rating && <span className="text-sm"> ({rating})</span>}
            </div>
            <div className="sky-h5 lg:sky-h7">
              {format(selectedEvent?.startMs, 'EEEE')} {format(selectedEvent?.startMs, 'd')}{' '}
              {format(selectedEvent?.startMs, 'MMMM')} | {format(selectedEvent?.startMs, 'p')} -{' '}
              {format(selectedEvent?.endMs, 'p')} | Channel {selectedEvent?.channel?.number}
            </div>
            <p className="sky-sub md:sky-sub my-4">{eventDetail?.programme?.synopsis}</p>
          </div>

          <div className="w-full flex flex-col items-center justify-center md:w-2/5 lg:w-2/4 mx-5">
            {isRecordable && hasRecordableBox && (
              <>
                <div className="sky-h4 md:sky-h5 text-center font-semibold">Start Recording</div>
                <RecordButtons channel={channel} event={eventDetail!} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EventDetailNew;
