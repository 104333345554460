import React from 'react';

export const RemoteRecordIcon = () => (
  <svg width="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15C13.2091 15 15 13.2091 15 11C15 8.7909 13.2091 7 11 7C8.7909 7 7 8.7909 7 11C7 13.2091 8.7909 15 11 15Z"
      fill="#DD423C"
    />
    <path
      d="M21 11C21 16.5228 16.5228 21 11 21C5.4772 21 1 16.5228 1 11C1 5.4772 5.4772 1 11 1C16.5228 1 21 5.4772 21 11Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export const RemoteSeriesRecordIcon = () => (
  <svg width="32" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16.687 17.4354C18.721 15.6049 20 12.9518 20 10C20 7.04816 18.721 4.39505 16.687 2.56457C16.7499 2.5563 16.813 2.5488 16.8763 2.54209C19.8606 3.70365 21.9756 6.60484 21.9756 10C21.9756 13.3952 19.8606 16.2963 16.8763 17.4579C16.813 17.4512 16.7499 17.4437 16.687 17.4354ZM13.2197 19.4704C13.4691 19.49 13.7212 19.5 13.9756 19.5C15.0633 19.5 16.1082 19.3172 17.0814 18.9807C17.2778 18.9935 17.4759 19 17.6756 19C22.6461 19 26.6756 14.9706 26.6756 10C26.6756 5.02944 22.6461 1 17.6756 1C17.4759 1 17.2778 1.0065 17.0814 1.0193C16.1082 0.682779 15.0633 0.5 13.9756 0.5C13.7212 0.5 13.4691 0.510001 13.2197 0.529633C12.2094 0.186249 11.1264 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C11.1264 20 12.2094 19.8138 13.2197 19.4704ZM20.4163 3.01656C22.2974 4.75234 23.4756 7.23858 23.4756 10C23.4756 12.7614 22.2974 15.2477 20.4163 16.9834C23.2028 15.889 25.1756 13.1749 25.1756 10C25.1756 6.82513 23.2028 4.11102 20.4163 3.01656Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13C11.933 13 13.5 11.433 13.5 9.5C13.5 7.567 11.933 6 10 6C8.067 6 6.5 7.567 6.5 9.5C6.5 11.433 8.067 13 10 13Z"
      fill="#F32836"
    />
  </svg>
);
