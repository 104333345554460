import React, { useEffect, useState } from 'react';
import useStore from '../../../store';
import { useAuth0 } from '../../../store/authStore/authStore';
import { LoggedinModalContent } from './content/LoggedInModalContent';
import { LoggedOutModalContent } from './content/LoggedOutModalContent';
import { SPLITIO_KEY, T_Product, productSkuIds, useSplitIO } from '@sky-tv-group/shared';
import { Slot,T_EPG_Channel_New } from '../../../type';
interface Props {
  isSeriesRecord: boolean;
  remoteRecording: (cardId: string) => void;
  closeModal: () => void;
  event: Slot;
  channel: T_EPG_Channel_New;
  missingSubscription?: T_Product;
}

const RecordModalManager = ({
  isSeriesRecord,
  remoteRecording,
  closeModal,
  event: selectedEvent,
  channel: selectedChannel,
  missingSubscription,
}: Props) => {
  const [nsbRemoteRecording] = useSplitIO(SPLITIO_KEY.NSB_REMOTE_RECORDING);
  const { client, isAuthenticated } = useAuth0();
  const occurrences = useStore(s => s.occurrences)?.filter(o => o.isRemoteRecordCapable);
  const occurrencesfinal = occurrences?.filter(o => {
    // If it's a NSB don't show remote recording as an option yet
    // This is going to be toggled on using the NSB_REMOTE_RECORDING feature flag
    if (
      // If a NSB
      o.entitlements.find(
        e => e.code === productSkuIds?.arrowBox?.primary || e.code === productSkuIds?.arrowBoxBlack?.primary
      ) &&
      // And the NSB remote recording flag is off
      !nsbRemoteRecording
    )
      return false;

    return o.isRemoteRecordCapable;
  });

  const [selectedOccurence, setSelectedOccurence] = useState(
    occurrencesfinal && occurrencesfinal.length > 0 ? occurrencesfinal[0].serialNumber : ''
  );


  const handleLogin = () => {
    // Store event and channel in Session to resume after login redirect
    sessionStorage.setItem('event', JSON.stringify(selectedEvent));
    sessionStorage.setItem('channel', JSON.stringify(selectedChannel));
    sessionStorage.setItem('isSeriesRecord', JSON.stringify(isSeriesRecord));
    if (client) {
      client.loginWithRedirect().catch((err: Error) => {
        console.error(err);
      });
    }
  };

  const handleBoxSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOccurence(e.target.value);
  };

  return (
    <div className="p-2 m-4">
      {isAuthenticated ? (
        <LoggedinModalContent
          selectedChannel={selectedChannel}
          selectedEvent={selectedEvent}
          closeModal={closeModal}
          handleBoxSelect={handleBoxSelect}
          isSeriesRecord={isSeriesRecord}
          occurrences={occurrencesfinal}
          remoteRecording={remoteRecording}
          selectedOccurence={selectedOccurence}
          missingSubscription={missingSubscription}
        />
      ) : (
        <LoggedOutModalContent
          selectedChannel={selectedChannel}
          selectedEvent={selectedEvent}
          handleLogin={handleLogin}
        />
      )}
    </div>
  );
};

export default RecordModalManager;
