import { gql } from '@apollo/client';

export const searchResults = gql`
  query searchResults($term: String!, $from: DateTime, $after: String) {
    experience(appId: TV_GUIDE_WEB) {
      search(term: $term) {
        slotResultsPage(filter: { from: $from }, first: 25, after: $after) {
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
          content {
            id
            startMs
            endMs
            recordOptions
            recordingSeriesLink {
              id
            }
            channel {
              id
              title
              number
              tileImage {
                uri
              }
            }
            programme {
              ... on Episode {
                id
                title
                synopsis
                show {
                  id
                  type
                  title
                }
              }
              ... on Movie {
                id
                title
                synopsis
              }
              ... on PayPerViewEventProgram {
                id
                title
                synopsis
              }
            }
          }
        }
      }
    }
  }
`;
