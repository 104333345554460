import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';

import { FULL_RANGE, MINUTES_OF_MONTH, PIXEL_PER_MINUTE, TIMESLOT_STEP } from '../constants';
import useStore, { store } from '../store';
import { time2Pixel } from '../utils';

const TimeSlots = () => {
  const init = useStore(s => s.init);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const unsub = store.subscribe(
      (cursor: any) => {
        if (ref.current) {
          ref.current.scrollTo(cursor, 0);
        }
      },
      s => s.x
    );
    return unsub;
  }, [init]);

  const start = Math.ceil(init / TIMESLOT_STEP) * TIMESLOT_STEP;
  let times = [];
  for (let i = 0; i * TIMESLOT_STEP < FULL_RANGE; i++) {
    times.push(i * TIMESLOT_STEP + start);
  }
  return (
    <div ref={ref} className="overflow-x-hidden w-full">
      <div className="relative h-12" style={{ width: MINUTES_OF_MONTH * PIXEL_PER_MINUTE }}>
        {times.map(time => {
          return (
            <div
              key={time}
              className="absolute h-full"
              style={{
                transform: `translateX(${time2Pixel(time - init)}px)`,
              }}>
              <span className="leading-12 sky-sub sm:sky-h7">{format(time, 'p')}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeSlots;
