import { gql } from '@apollo/client';

export const getCustomerDetails = gql`
  query getCustomerDetails {
    customer {
      id
      profile {
        id
        name
      }
    }
  }
`;
