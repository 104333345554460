import { gql } from '@apollo/client';

export const getChannelGroup = gql`
  query getChannelGroup($id: ID!, $date: LocalDate) {
    experience(appId: TV_GUIDE_WEB) {
      channelGroup(id: $id) {
        id
        title
        channels {
          ... on LinearChannel {
            id
            title
            number
            tileImage {
              uri
            }
            slotsForDay(date: $date) {
              slots {
                id
                startMs
                endMs
                live
                programme {
                  ... on Episode {
                    id
                    title
                    show {
                      id
                      title
                      type
                    }
                  }
                  ... on Movie {
                    id
                    title
                  }
                  ... on PayPerViewEventProgram {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
