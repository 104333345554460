import React from 'react';
import { T_Product, telPhoneNumber, unformattedTelPhoneNumber } from '@sky-tv-group/shared';
import { Button } from '@sky-tv-group/components';

import { ACCOUNT_URL } from '../../../../config';
import { myAccountSWR } from '../../../../services';

interface PurchaseModalProps {
  missingSubscription: T_Product;
  closeModal: () => void;
}

export const PurchaseModalContent = ({ missingSubscription, closeModal }: PurchaseModalProps) => {
  let { broadbandOnlyAccount } = myAccountSWR.usePackages();
  // we show message for BB only first
  if (broadbandOnlyAccount) {
    return (
      <div className="text-gray-text">
        <h4 className="sky-h3 md:sky-h5 text-center font-bold py-8">Upgrade to Sky TV customer</h4>
        <p className="text-center m-auto pb-4" style={{ maxWidth: 600 }}>
          Upgrade to Sky TV customer. <br />
          Love these TV programs? Please upgrade to our Sky TV service with our Sky crew, call our customer services
          team &nbsp;
          <a className="block md:inline underline font-bold" href={`tel:${unformattedTelPhoneNumber}`}>
            {telPhoneNumber}.
          </a>
        </p>
        <div className="flex flex-no-wrap justify-center  mx-auto my-2  ">
          <Button variant="primary" colorMode="light" onClick={closeModal}>
            Okay
          </Button>
        </div>
      </div>
    );
  }

  // if customer is not BB only , but still missing some product, then show below message.
  return (
    <div>
      <h4 className="sky-h3 md:sky-h5 mx-auto my-10 md:w-3/4">
        You are not currently subscribed to <b>{missingSubscription.name}</b>. To record this show, please upgrade your
        subscription.
      </h4>
      <div className="flex flex-no-wrap justify-center md:justify-end w-11/12  mx-auto my-2 md:my-0">
        <Button variant="secondary" colorMode="light" onClick={closeModal}>
          Cancel
        </Button>
        <a href={`${ACCOUNT_URL}packages/manage-subscription?skus=${missingSubscription.sku}`}>
          <Button variant="primary" colorMode="light">
            Add {missingSubscription.name}
          </Button>
        </a>
      </div>
    </div>
  );
};
