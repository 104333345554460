import React from 'react';
import { RemoteRecordIcon, RemoteSeriesRecordIcon } from '../icons/RecordIcon';

interface RecordBtnProps {
  variant: 'Episode' | 'Series';
}
export let RecordButton = ({
  variant,
  ...rest
}: RecordBtnProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button className="rounded-full whitespace-no-wrap bg-navy text-white mx-4 hover:shadow-md md:w-40" {...rest}>
      <div className="flex my-2 py-1 text-lg">
        <div className="ml-4 mr-2 w-1/4">
          {variant === 'Episode' && <RemoteRecordIcon />}
          {variant === 'Series' && <RemoteSeriesRecordIcon />}
        </div>
        <div className="pl-2 pr-4 w-3/4">{variant}</div>
      </div>
    </button>
  );
};
