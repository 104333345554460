import { gql } from '@apollo/client';

export const getSlot = gql`
  query getSlot($slotId: ID!) {
    experience(appId: TV_GUIDE_WEB) {
      linearChannelSlot(id: $slotId) {
        live
        nzBsaRatingString
        recordOptions
        recordingSeriesLink {
          id
        }
        programme {
          ... on Episode {
            synopsis
          }
          ... on PayPerViewEventProgram {
            synopsis
          }
          ... on Movie {
            synopsis
          }
        }
      }
    }
  }
`;
