import { PIXEL_PER_MINUTE } from './constants';

export const ms2Minutes = (ms: number) => ms / 60 / 1000;
export const second2Minutes = (ms: number) => ms / 60;

export const time2Pixel = (time: number) => {
  return Math.floor((time / 1000 / 60) * PIXEL_PER_MINUTE);
};
export const pixel2Time = (pixel: number) => {
  return Math.floor((pixel / PIXEL_PER_MINUTE) * 1000 * 60);
};
