import './index.css';
import './main.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import { Root } from './Root';
import { sentryService } from './services';

sentryService.init();
Modal.setAppElement('#root');
ReactDOM.render(<Root />, document.getElementById('root'));
