import React, { useEffect } from 'react';
import { useDrag } from 'react-use-gesture';
import { Loader } from '@sky-tv-group/components';
import useStore, { useChannels } from '../store';
import { TVGuideHeader } from './TVGuideHeader';
import { TVGuideControl } from './TVGuideControl';
import ChannelRailNew from './ChannelRailNew';

const Slider = () => {
  const updateDrag = useStore(s => s.updateDrag);
  //const newChannels = useChannelsNew();
  const newChannels = useStore(s => s.newChannels);
  const bind = useDrag(updateDrag, {
    axis: 'x',
    swipeDistance: 0,
    swipeVelocity: 0.1,
  });
  const loading = useStore(s => s.loading);
  const hasErrorSet = useStore(s => s.hasErrorSet);
  //console.log('channels======', newChannels);
  const { fetch, genreId } = useStore(s => ({
    fetch: s.fetch,
    genreId: s.genreId,
  }));

  useEffect(() => {
    let doFetch = async () => {
      try {
        await fetch();
      } catch (err) {
        hasErrorSet(true);
      }
    };
    doFetch();
  }, [fetch, hasErrorSet, genreId]);

  return (
    <>
      <div className="sticky top-4 z-20 bg-white">
        <TVGuideHeader />
        <TVGuideControl />
      </div>
      <div className="overflow-hidden relative" style={{ zIndex: 9 }}>
        <div className="w-screen-90 flex my-0 mx-auto relative z-10">
          <div {...bind()} className={`w-full ${loading ? 'min-h-screen' : ''}`}>
            {loading && (
              <div className="absolute w-full h-screen">
                <Loader bgOpacity={false} />
              </div>
            )}
            {/* {channels.map(channel => {
              return <ChannelRail key={channel.number} {...{ channel: channel }} />;
            })} */}
            {newChannels.map(channel => {
              return <ChannelRailNew key={channel.number} {...{ channel: channel }} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Slider;
