import { parseQuery } from '@sky-tv-group/shared';
import { BlockLoader } from '@sky-tv-group/components';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { RecordButtons } from './event/RecordButtons';
import { fetchSearchResults } from '../services';
import useStore from '../store';
import { TVGuideHeader } from './TVGuideHeader';
import { Slot, T_EPG_Channel_New } from '../type';
import InfiniteScroll from 'react-infinite-scroll-component';
interface SearchResultItemProps {
  event: Slot;
  channel: T_EPG_Channel_New;
  title: String;
}
let SearchResultItem = ({ event, channel, title }: SearchResultItemProps) => {
  return (
    <div data-testid="search-epg-result-item" className="flex flex-wrap border-b py-6">
      <div className="w-full lg:w-7/12 ">
        <p className="sky-h5 md:sky-h5 text-navy font-bold">
          {title} | {channel?.title} - {`Channel ${channel?.number}`}
        </p>
        <div className="sky-text-midnight md:sky-h7 pb-2">
          {/* <span>{event.start}</span> */}
          <span>{format(event.startMs, 'EEEE d LLL')}</span>
          <span> | </span>
          <span>
            {format(event.startMs, 'p')} - {format(event.endMs, 'p')}
          </span>
        </div>
        <p className="sky-text-gray md:sky-h7">{event?.programme?.synopsis}</p>
      </div>
      <div className="w-full lg:w-5/12 ">
        <RecordButtons channel={channel} event={event} className="justify-end" />
      </div>
    </div>
  );
};

export let Search = () => {
  let location = useLocation();
  let query = parseQuery(location.search);

  const channels = useStore(s => s.newChannels);
  const updateChannelFilter = useStore(s => s.updateChannelFilter);
  const { channelNumber } = useParams<{ channelNumber: string }>();
  const titleValue = useStore(s => s.getTitleFromEvent);

  // get events on the current selected day
  const channel = channels.find(ch => ch.number === channelNumber);
  const [events, setEvents] = useState<Slot[]>([]);
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [pageInfo, setPageInfo] = useState({});

  const getSearchResults = async () => {
    let result = await fetchSearchResults(query.query as string, '');
    // console.log("fetchSearchResults 1", result)
    // console.log("fetchMoreData", result?.pageInfo)
    setEvents(result?.content);
    setPageInfo(result?.pageInfo);
    setLoading(false);
  };

  useEffect(() => {
    if (query.query !== '' && query.query != undefined) {
      getSearchResults();
    }
  }, [query.query]);

  useEffect(() => {
    setEvents([]);
  }, [query.query]);

  // update channel for search
  useEffect(() => {
    updateChannelFilter(channel);
    return () => {
      updateChannelFilter(); // reset channel filter
    };
  }, [channel ? channel.number : null]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = async () => {
    // console.log("fetchMoreData Load", pageInfo?.hasNextPage)
    if (pageInfo?.hasNextPage) {
      let result = await fetchSearchResults(query.query as string, pageInfo?.endCursor);
      // console.log("fetchSearchResults 2", result)
      // console.log("fetchMoreData", result?.pageInfo)
      setPageInfo(result?.pageInfo);
      setEvents(prevItems => [...prevItems, ...result?.content]);
    }
  };

  if (loading) {
    return <BlockLoader />;
  }
  return (
    <>
      <div className="sticky top-4 z-20 bg-white">
        <TVGuideHeader />
      </div>
      <div className="overflow-hidden relative" style={{ zIndex: 9 }}>
        <div className="w-screen-90 flex my-0 mx-auto relative z-10">
          {events?.length === 0 ? (
            <div className="text-navy mx-auto py-5 sky-h3">No results.</div>
          ) : (
            <InfiniteScroll
              key={channel?.number}
              dataLength={events.length}
              next={fetchMoreData}
              hasMore={pageInfo?.hasNextPage}
              loader={pageInfo?.hasNextPage && <BlockLoader />}
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            >
              <div className="w-full">
                {events?.map(
                  (event, index) =>
                    event?.programme && (
                      <SearchResultItem
                        key={event?.programme?.id + index}
                        title={titleValue(event?.programme)}
                        event={event}
                        channel={event?.channel}
                      />
                    )
                )}
                {/* {pageInfo?.hasNextPage == true &&
                  <button
                    onClick={() => fetchMoreData()}
                    className="ml-auto w-12 h-12 text-white rounded-full bg-gray-darkest transform hover:bg-black hover:rotate-180 transition duration-500 ease-in-out">
                  </button>
                } */}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
};
