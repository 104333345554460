import React, { useEffect } from 'react';
import { Link, Route, useLocation, useParams } from 'react-router-dom';
import { caret } from '@sky-tv-group/components';
import { Filter } from './Filter';
import { SearchInput } from './SearchInput';
import useStore from '../store';

export let TVGuideHeader = () => {
  const { newChannels } = useStore(s => ({
    newChannels: s.newChannels,
  }));
  const { channelNumber } = useParams<{ channelNumber: string }>();
  const channel = newChannels.find(ch => ch.number == channelNumber);

  // const location = useLocation();

  //console.log('channels==', channels);
  /* Check URL for filter parameter. */
  // useEffect(() => {
  //   let handleQueries = async () => {
  //     if (location.search) {
  //       let queries = parseQuery(location.search);
  //       const filter = (queries.filter ?? '') as typeof genreFilters[number];
  //       if (filter) {
  //         updateGenre(filter);
  //         history.replace(location.pathname);
  //       }
  //     }
  //   };
  //   handleQueries();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.search]);

  return (
    <div className="flex flex-col w-screen-90 mx-auto py-5 ">
      <Route
        path="/channel/:channelNumber"
        exact
        children={({ match }) =>
          match && (
            <Link to="/" className="flex flex-row align-middle">
              <span className="w-6 h-6 z-10 transform rotate-90">{caret}</span>
              <span>Back to all channels</span>
            </Link>
          )
        }
      />
      <div className="flex flex-wrap justify-between items-center ">
        <h1 className="text-navy sky-h2 md:sky-h2 whitespace-no-wrap">TV Guide</h1>
        <Route
          path="/channel/:channelNumber"
          exact
          children={({ match }) => {
            return (
              <div className={match ? 'visible' : 'invisible'}>
                {channel && (
                  <div
                    className="w-24 h-16 bg-center bg-contain bg-no-repeat"
                    style={{
                      backgroundImage: `url("${channel?.tileImage?.uri}")`,
                    }}
                  />
                )}
              </div>
            );
          }}
        />
        <Route
          path="/"
          exact
          children={({ match, location }) => {
            // if we're in single channel page, remove the Filter el thru hidden class
            return (
              <div
                className={
                  match ? 'visible' : `invisible ${location.pathname.includes('channel') ? 'hidden md:block' : ''}`
                }>
                <Filter />
              </div>
            );
          }}
        />
        <SearchInput />
      </div>
    </div>
  );
};
