// styling
export const PIXEL_PER_MINUTE = 10;

// time
export const FULL_RANGE = 1000 * 60 * 60 * 24 * 30;
export const MINUTES_OF_MONTH = 60 * 24 * 30;
export const FETCH_RANGE = 1000 * 60 * 60 * 4;
export const SINGLE_CHANNEL_BUFFER = 4;
export const FETCH_BUFFER = 2;
export const MINUTE_TO_MS = 1000 * 60;
export const TIMESLOT_STEP = 1000 * 60 * 30;
export const DAY_STEP = 1000 * 60 * 60 * 24;

// fetch
export const LIMIT = 20000;
