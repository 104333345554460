import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../store';
import TimeSlots from './TimeSlots';
import WeekSlots from './WeekSlots';
import { Arrow } from './icons/ArrowButton';
import { PIXEL_PER_MINUTE } from '../constants';

const MemoizedWeekSlots = React.memo(WeekSlots);

export let TVGuideControl = () => {
  const { channelNumber } = useParams<{ channelNumber?: string | undefined }>();
  const x = useStore(s => s.x);
  const setX = useStore(s => s.setX);
  const hasErrorSet = useStore(s => s.hasErrorSet);
  const { fetch, setFrom, setTo, dayOfX } = useStore(s => ({
    fetch: s.fetch,
    setFrom: s.setFrom,
    setTo: s.setTo,
    dayOfX: s.dayOfX, // current selected day in the weekslots
  }));

  useEffect(() => {
    let doFetch = async () => {
      try {
        await fetch();
      } catch (err) {
        hasErrorSet(true);
      }
    };
    doFetch();
  }, [fetch, hasErrorSet, x]);

  useEffect(() => {
    goto();
  }, [dayOfX]);

  const goto = () => {
    let day = dayOfX.split('/');
    let fromDate = `${day[2]}-${day[0]}-${day[1]}`;
    setFrom(fromDate);
  };

  // Time scroll for 1 hour.
  const scrollNext = () => {
    setX(x + PIXEL_PER_MINUTE * 60);
  };

  // Go back 1 hour.
  const scrollPrev = () => {
    if (x >= 600) {
      setX(x - PIXEL_PER_MINUTE * 60);
    }
  };

  return (
    <div>
      <div className="bg-navy">
        <div className="w-screen-90 mx-auto">
          <MemoizedWeekSlots />
        </div>
      </div>
      {!channelNumber && (
        <div className="bg-navy text-white mt-1">
          <div className="w-screen-90 flex my-0 mx-auto">
            <div className="w-24 md:w-32 flex items-center">
              <div className="sky-sub sm:sky-h7 text-center mx-auto">Channels</div>
            </div>
            <div className="sky-sub sm:sky-h7 text-center flex items-center mx-auto">
              <Arrow invert={true} colorClassName="text-white" onClick={scrollPrev} defaultHide={false} />
            </div>
            <TimeSlots />
            <div className="sky-sub sm:sky-h7 text-center flex items-center mx-auto">
              <Arrow invert={false} colorClassName="text-white" onClick={scrollNext} defaultHide={false} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
