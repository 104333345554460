import React, { useEffect, useState } from 'react';

import useStore from '../store';
import { caret } from '@sky-tv-group/components';
import { gqlAgentMyAccount } from '../services/agent';
import { getChannelGroups } from '../graphQL/queries/getChannelGroups';

export const Filter = () => {
  const genre = useStore(s => s.genre);
  const updateGenreId = useStore(s => s.updateGenreId);
  const [genres, setGenres] = useState([]);
  const [channelGroups, setChannelGroups] = useState([]);

  const getAllGenres = async () => {
    const res = await gqlAgentMyAccount.query({
      query: getChannelGroups,
    });

    setChannelGroups(res?.data?.experience?.channelGroups);
    let genresArr: any = [];
    res?.data?.experience?.channelGroups.map(item => {
      genresArr.push(item.title);
    });

    setGenres(genresArr);
  };

  useEffect(() => {
    getAllGenres();
  }, []);

  const onChange = (selectedValue: string) => {
    // console.log('selectedGenre', selectedValue);
    let selectedGenre = channelGroups.filter(item => item.title === selectedValue);
    updateGenreId(selectedGenre[0]?.id);
  };
  return (
    <div className="relative my-4 sm:ml-8 lg:ml-32 xl:ml-96">
      <select
        className="w-full sky-input sky-input--text sky-input--navy appearance-none sky-h5 md:sky-h7"
        value={genre}
        // onChange={e => updateGenre(e.target.value as typeof genreFilters[number] | '')}
        onChange={e => onChange(e.target.value)}>
        {/* <option value="">All Channels</option> */}
        {genres.map(f => (
          <option key={f} value={f}>
            {f}
          </option>
        ))}
      </select>
      <div className="w-10 h-12 py-2 mr-1 absolute text-gray-darker top-0 right-0 pointer-events-none">{caret}</div>
    </div>
  );
};
