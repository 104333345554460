import { T_Product, T_Billing } from '../types';
import { categoryIds } from '../config';

/**
 * get the user's subscription packages
 * return the user subscription's list of packages.
 */
const useSubscribedPackages = () => {
  const getSubscribedPackages = async (products: T_Product[], billing: T_Billing): Promise<T_Product[]> => {
    // the first occurrence which will be the default box
    let billingItems = billing?.services.CableServiceOccurrence?.find(serviceDetail => serviceDetail.status === 1)
      ?.serviceDetails;
    let data =
      products
        ?.map(product => {
          let quantity = 0;
          if (product.categoryId !== categoryIds.box && product.categoryId !== categoryIds.multiroom) {
            // normal product, normally should be 1 or 0.
            let foundService = billingItems?.find(e => e.serviceCode === product.sku);
            if (foundService) {
              quantity = foundService.quantity;
            }
          }
          return {
            ...product,
            quantityBought: quantity,
          } as T_Product;
        })
        .filter(product => product.quantityBought > 0) ?? [];
    return data;
  };

  return { getSubscribedPackages };
};

export { useSubscribedPackages };
