import { gql } from '@apollo/client';

export const getChannelGroups = gql`
  query getChannelGroups {
    experience(appId: TV_GUIDE_WEB) {
      channelGroups {
        id
        title
      }
      appId
    }
  }
`;
