import { format, isToday } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { DAY_STEP } from '../constants';
import useStore from '../store';
import { time2Pixel } from '../utils';
import { useDragDetection } from '@sky-tv-group/shared';
import { CarouselContainer, ISliderProps } from '@sky-tv-group/components';

import { Arrow } from './icons/ArrowButton';

const WeekSlots = () => {
  const init = useStore(s => s.init);
  const dayOfX = useStore(s => s.dayOfX);
  const setX = useStore(s => s.setX);
  const [days, setDays] = useState<number[]>([]);
  const { dragging, handleMouseDown } = useDragDetection();
  const { fetch, setFrom, setTo } = useStore(s => ({
    fetch: s.fetch,
    setFrom: s.setFrom,
    setTo: s.setTo,
  }));
  const hasErrorSet = useStore(s => s.hasErrorSet);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    const d = [];
    for (let i = 0; i < 28; i++) {
      d.push(i * DAY_STEP + init);
    }
    setDays(d);
  }, [init]);

  const doFetch = async () => {
    try {
      fetch();
    } catch (err) {
      hasErrorSet(true);
    }
  };

  const goto = (time: number) => {
    setX(time2Pixel(time - init));

    const currentYear = new Date().getFullYear();
    let month = format(time, 'MM');
    let date = format(time, 'dd');
    let fromDate = `${currentYear}-${month}-${date}`;
    let toDate = `${currentYear}-${month}-${date}T23:01:28Z`;
    setFrom(fromDate);
    setTo(toDate);
    doFetch();
  };

  const slickSettings: ISliderProps = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    swipeToSlide: false,
    arrows: true,
    responsiveBlock: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="mx-auto lg:w-13/14">
      <div className="relative h-full">
        <CarouselContainer
          {...slickSettings}
          arrowColorClassName="text-white"
          nextArrow={<Arrow invert={false} colorClassName="text-white" />}
          prevArrow={<Arrow invert={true} colorClassName="text-white" />}>
          {days.map(time => {
            let current = dayOfX === format(time, 'P');
            return (
              <div
                key={time}
                className={`cursor-pointer px-1 py-4 w-16 md:w-24 lg:w-32 xl:w-40 md:p-2 mx-auto transform scale-90 ${
                  current ? 'border-b-8 border-blue-light scale-100' : ''
                }`}
                onMouseDownCapture={handleMouseDown}
                onClickCapture={(e: React.MouseEvent) => {
                  if (dragging) {
                    e.preventDefault();
                  } else {
                    goto(time);
                  }
                }}>
                <div className="text-white text-center md:sky-h7">
                  <div className="hidden lg:block font-bold">{isToday(time) ? 'Today' : format(time, 'EEEE')}</div>
                  <div className="block lg:hidden font-bold">{isToday(time) ? 'Today' : format(time, 'iii')}</div>
                  <div>
                    {format(time, 'd')} {format(time, 'LLL')}
                  </div>
                </div>
              </div>
            );
          })}
        </CarouselContainer>
      </div>
    </div>
  );
};

export default WeekSlots;
