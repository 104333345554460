import { gql } from '@apollo/client';

export const getsearch = gql`
query search($term: String!) {
  experience(appId: TV_GUIDE_WEB) {
    search(term: $term) {
      results {
        title
      }
    }
  }
}`;
