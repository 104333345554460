import React from 'react';
import cx from 'classnames';
import { caret } from '@sky-tv-group/components';

interface Props {
  className?: any;
  onClick?: (e: any) => void;
  invert: boolean;
  colorClassName: string;
  defaultHide?: boolean;
}

const Arrow: React.FC<Props> = ({ className, onClick, invert, colorClassName, defaultHide = true }: Props) => {
  const defaultClasses = `w-8 h-8 z-10 ${defaultHide ? 'hidden' : ''} lg:block`;
  const arrowClasses = cx(defaultClasses, colorClassName, {
    'transform rotate-90': invert,
    'transform -rotate-90': !invert,
  });

  return (
    <>
      <button className={className} onClick={onClick}>
        <div className={arrowClasses}>{caret}</div>
      </button>
    </>
  );
};

export { Arrow };
